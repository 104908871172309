import Authenticator from "../authenticator/authenticator";
import JsonRestApiClient from "../jsonRestApiClient";

export default class AxisFunctionsService {
  private readonly api: JsonRestApiClient;

  constructor(
    private readonly baseUrl: string,
    private readonly authenticator?: Authenticator
  ) {
    this.api = new JsonRestApiClient(`${this.baseUrl}/api`, this.authenticator);
  }

  readonly createMarketplaceInstallerCountReportAllInstallers = async (
    formDataFileName: string,
    file: any,
    fileName: string,
    sheetName: string,
    zipCodeColumnNo: number,
    email: string,
    listOfRadius: number[]
  ) => {
    const uri =
      "/installer-service/reports/marketplace-installer-count-report-all-installers?";
    const params = new URLSearchParams();
    params.append("sheetName", sheetName);
    params.append("zipCodeColumnNo", zipCodeColumnNo.toString());
    params.append("email", email);
    params.append("listOfRadius", listOfRadius.toString());
    try {
      const formData = new FormData();
      formData.append(formDataFileName, file, fileName);
      return await this.api.postFormData(uri + params.toString(), formData);
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  };

  readonly createMarketplaceInstallerCountReportCustomInstallers = async (
    marketplaceStoresFormDataFileName: string,
    marketplaceStoresFile: any,
    marketplaceStoresFileName: string,
    selectedInstallerFormDataFileName: string,
    selectedInstallerFile: any,
    selectedInstallerFileName: string,
    marketplaceStoresFileSheetName: string,
    marketplaceStoresFileZipCodeCellNo: number,
    selectedInstallerFileSheetName: string,
    selectedInstallerFileZipCodeCellNo: number,
    email: string,
    listOfRadius: number[]
  ) => {
    const uri =
      "/installer-service/reports/marketplace-installer-count-report-selected-installers?";
    const params = new URLSearchParams();
    params.append(
      "marketplaceStoresFileSheetName",
      marketplaceStoresFileSheetName
    );
    params.append(
      "marketplaceStoresFileZipCodeColumnNo",
      marketplaceStoresFileZipCodeCellNo.toString()
    );
    params.append(
      "selectedInstallerFileSheetName",
      selectedInstallerFileSheetName
    );
    params.append(
      "selectedInstallerFileZipCodeColumnNo",
      selectedInstallerFileZipCodeCellNo.toString()
    );
    params.append("email", email);
    params.append("listOfRadius", listOfRadius.toString());
    try {
      const formData = new FormData();
      formData.append(
        marketplaceStoresFormDataFileName,
        marketplaceStoresFile,
        marketplaceStoresFileName
      );
      formData.append(
        selectedInstallerFormDataFileName,
        selectedInstallerFile,
        selectedInstallerFileName
      );
      return await this.api.postFormData(uri + params.toString(), formData);
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  };

  readonly uploadProductManagementFile = async (
      email: string,
      operation: string,
      selectedFile: File,
      notes: string | null,
  ) => {
    const uri =
        "/product-service/management/products/upload?";
    const params = new URLSearchParams();
    params.append("operation", operation);
    params.append("email", email);
    if(!!notes) {
      params.append("notes", notes);
    }
    try {
      const formData = new FormData();
      formData.append(
          "multipartFile",
          selectedFile
      );
      return await this.api.postFormData(uri + params.toString(), formData);
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  };
}
