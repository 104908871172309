import React from 'react';
import './App.css';
import AtdMockOrdersPage from "./pages/AtdMockOrdersPage/AtdMockOrdersPage";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import PageContainer from "./components/PageContainer";
import FeatureFlagsPage from "./pages/FeatureFlagsPage/FeatureFlagsPage";
import {MsalProvider} from "@azure/msal-react";
import AllInstallerDistanceReportPage from "./pages/AllInstallerDistanceReportPage/AllInstallerDistanceReportPage";
import CustomInstallerDistanceReportPage
    from "./pages/CustomInstallerDistanceReportPage/CustomInstallerDistanceReportPage";
import ProductFileUploadPage from "./pages/ProductManagement/ProductFileUpload/ProductFileUploadPage";

function App({ pca }: {pca: any}) {

    const routes = [
        {
            path: "/mock-orders",
            element: <AtdMockOrdersPage/>,
        },
        {
            path: "/feature-flags",
            element: <FeatureFlagsPage/>,
        },
        {
            path: "/installer-distance-report-all",
            element: <AllInstallerDistanceReportPage/>,
        },
        {
            path: "/installer-distance-report-custom",
            element: <CustomInstallerDistanceReportPage/>,
        },
        {
            path: "/product-management/file-uploads",
            element: <ProductFileUploadPage/>,
        },
        {
            path: "/",
            element: <Navigate to="/feature-flags"/>
        }
    ];

    const browserRoutes = routes.map(route => {
        return {
            path: route.path,
            element: <PageContainer component={route.element}/>,
        }
    });

    const router = createBrowserRouter(browserRoutes);

    return (
        <MsalProvider instance={pca}>
            <RouterProvider router={router}/>
        </MsalProvider>
    );
}

export default App;
