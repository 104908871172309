import "./ProductFileUploadPage.css";
import {useEffect, useState} from "react";
import {Alert, Autocomplete, Button, Container, Slide, Snackbar, Stack, TextField,} from "@mui/material";
import Typography from "@mui/material/Typography";
import client from "../../../client";
import {useMsal} from "@azure/msal-react";

interface FileOperation {
    id: number,
    label: string,
    operation: string
}

const ProductFileUploadPage = () => {
    const [
        requestSubmittedSuccessfullyDialog,
        setRequestSubmittedSuccessfullyAlert,
    ] = useState<boolean>(false);

    const [fileOperation, setFileOperation] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [email, setEmail] = useState<string>("");
    const [notes, setNotes] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {instance, accounts} = useMsal();

    useEffect(() => {
        const account = instance.getAccountByHomeId(accounts[0]?.homeAccountId);

        if (account) {
            setEmail(account.username);
        }
    }, [instance, accounts]);

    const uploadFile = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    const onNotesChange = (event: any) => {
        const notes = event.target.value;
        if (!!notes) {
            setNotes(notes);
        }
    };

    const onSubmit = () => {
        console.log(`onSubmit() called`);
        if (!selectedFile) {
            setErrorMessage('Please select a file.');
            return;
        }
        client.axisFunctions
            .uploadProductManagementFile(email, fileOperation, selectedFile, notes)
            .then(() => {
                setRequestSubmittedSuccessfullyAlert(true);
            });
    };

    const productFileOperations: FileOperation[] = [
        {label: 'Selling Price Update', id: 1, operation: "SELLING_PRICE_UPDATE"},
        {label: 'Brand Style Ranking Update', id: 2, operation: "BRAND_STYLE_RANKING_UPDATE"},
        {label: 'Wholesale Price Update', id: 3, operation: "WHOLESALE_PRICE_UPDATE"},
        // TODO: Needs to be uncommented once API implementation is complete
        // {label: 'Product Description Update', id: 4, operation: "PRODUCT_DESCRIPTION_UPDATE"},
        // {label: 'MIR Mapping Update', id: 5, operation: "MIR_MAPPING_CREATE"},
    ];

    const onFileOperationChange = (event: any, fileOperation: any) => {
        setFileOperation(fileOperation.operation);
    }

    return (
        <div className="SellingPriceUpdatePage">
            <br/>
            <Typography variant="h3" gutterBottom>
                Product Management - File Upload
            </Typography>
            <br/>
            <br/>
            <Container maxWidth="sm">
                <Stack spacing={2}>
                    <Autocomplete
                        disablePortal
                        options={productFileOperations}
                        disableClearable={true}
                        renderInput={(params) => <TextField {...params} label="File Operation"/>}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={onFileOperationChange}
                    />
                    <TextField type="file" onChange={uploadFile}/>
                    <TextField
                        value={email}
                        disabled={true}
                    />
                    <TextField
                        value={notes}
                        multiline={true}
                        onChange={onNotesChange}
                        placeholder="Notes"
                    />
                    <Button variant="contained" onClick={onSubmit} disabled={!selectedFile || !fileOperation}>
                        Submit
                    </Button>
                </Stack>
            </Container>
            <Snackbar
                open={requestSubmittedSuccessfullyDialog}
                onClose={() => setRequestSubmittedSuccessfullyAlert(false)}
                autoHideDuration={3000}
                TransitionComponent={TransitionRight}
            >
                <Alert severity="success">Report Request Submitted Successfully</Alert>
            </Snackbar>
            <Snackbar
                open={!!errorMessage}
                onClose={() => setErrorMessage("")}
                autoHideDuration={3000}
                TransitionComponent={TransitionRight}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </div>
    );
};

const TransitionRight = (props: any) => {
    return <Slide {...props} direction="right"/>;
};

export default ProductFileUploadPage;
